import React from 'react'
import UploadNewReportModal from './upload_new_report_modal'
import { useDisclosure, Stack } from '@chakra-ui/react'
import UploadDarkWebCreditCardReportModal from './dark_web/upload_new_darkweb_credit_card_report_modal'
import DoppelDefaultButton from '../shared/doppel_default_button'
import { IoMdArrowUp } from 'react-icons/io'
import { DarkWebPlatforms, ReportType } from '@/utils/constants'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { useAtom } from 'jotai'
import { useSelectedReportType } from '@/pages/reports'
import UploadDarkWebCredentialLeakReportModal from './dark_web/upload_new_darkweb_credential_leak_report_modal'

export default function UploadNewReportContainer({ refreshFunc }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)

  if (
    selectedReportType === ReportType.DARK_WEB &&
    [DarkWebPlatforms.GENERIC].includes(selectedReportExposureType)
  ) {
    return <></>
  }
  return (
    <>
      <Stack direction="row" spacing={4}>
        <DoppelDefaultButton
          leftIcon={<IoMdArrowUp size="12px" />}
          marginRight={4}
          onClick={onOpen}
        >
          Upload Alert
        </DoppelDefaultButton>

        <UploadNewReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />

        <UploadDarkWebCreditCardReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />

        <UploadDarkWebCredentialLeakReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />
      </Stack>
    </>
  )
}
