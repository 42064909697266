import {
  useNextSelectedSpoofReport,
  useSelectedSpoofReport,
} from '../../hooks/spoof_report_table_filters'
import SpoofDetailModal from './detail_modal/spoof_detail_modal'

// thin react component wrapper around SpoofDetailModal
export default function NavigableSpoofDetailModal({ loading }) {
  const [selectedSpoofReport, setSelectedSpoofReport] = useSelectedSpoofReport()
  const [nextSelectedSpoofReport, setNextSelectedSpoofReport] =
    useNextSelectedSpoofReport()

  const handleOnCompletedAction = () => {
    if (!nextSelectedSpoofReport) {
      setSelectedSpoofReport(null) // TODO go to next page
    } else {
      setSelectedSpoofReport(nextSelectedSpoofReport)
    }
  }
  return (
    <SpoofDetailModal
      handleOnCompletedAction={handleOnCompletedAction}
      loading={loading}
      onExitTapped={() => {
        setSelectedSpoofReport(null)
      }}
    />
  )
}
