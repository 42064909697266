import { Tab, Text, Tooltip } from '@chakra-ui/react'
import { DOPPEL_WHITE } from '../../../utils/style'
import { DarkWebPlatforms, DarkWebPlatformLabel } from '../../../utils/constants'

const PillTabs = ({
  reportExposureType,
  count,
  isLoading,
}: {
  reportExposureType: DarkWebPlatforms
  count: number
  isLoading: boolean
}) => {
  const tooltipLabel = getHeaderTooltip(reportExposureType)

  const content = (
    <Tab
      bgColor={'transparent'}
      key={'report_tab' + reportExposureType}
      textColor={DOPPEL_WHITE}
    >
      <Text fontSize={13} fontWeight="semibold">
        {DarkWebPlatformLabel[reportExposureType]}
      </Text>

      {/* <Box
        bgColor={DOPPEL_DARK_SECONDARY}
        borderRadius="full"
        marginLeft="2"
        minWidth="55px"
        paddingX="2"
      >
        {isLoading ? <Spinner size="xs" /> : count}
      </Box> */}
    </Tab>
  )
  if (!tooltipLabel) {
    return content
  }
  return (
    <Tooltip aria-label={tooltipLabel} label={tooltipLabel}>
      {content}
    </Tooltip>
  )
}

function getHeaderTooltip(reportType) {
  if (reportType == DarkWebPlatforms.CRED_LEAKS) {
    return 'Credential leaks found in the dark web'
  }
  if (reportType == DarkWebPlatforms.CREDIT_CARD_LEAKS) {
    return 'Credit card leaks found in the dark web'
  }
  if (reportType == DarkWebPlatforms.GENERIC) {
    return 'Other uncategorized leaks found in the dark web'
  }
  return null
}

export default PillTabs
