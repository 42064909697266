import { Flex, HStack, Link, Tag, VStack } from '@chakra-ui/react'
import React from 'react'
import { getDoppelLink } from '../../../utils/reports/report_utils'
import { DOPPEL_BREACH_RED } from '../../../utils/style'
import { unSnakeCase } from '../../../utils/string_utils'

export function UploadedReport({
  display_id,
  flagged_url,
  platform_subtype_name,
  status,
  brand_name,
  is_existing,
  product,
}) {
  return (
    <VStack align="flex-start" marginBottom={2}>
      <Flex alignItems="center">
        <Link fontSize={15} href={getDoppelLink(display_id, product)} marginRight={2}>
          {flagged_url}
        </Link>
      </Flex>

      <HStack>
        {display_id ? <Tag>{display_id}</Tag> : null}

        <Tag>{platform_subtype_name}</Tag>

        <Tag>{unSnakeCase(status)}</Tag>

        <Tag>{brand_name}</Tag>

        {is_existing && <Tag backgroundColor={DOPPEL_BREACH_RED}>Duplicate Report</Tag>}
      </HStack>
    </VStack>
  )
}
