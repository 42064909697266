import { Flex, HStack, Link, Tag, VStack } from '@chakra-ui/react'
import React from 'react'
import { unSnakeCase } from '../../../utils/string_utils'
import { useGetBrandNamesQuery } from '@/generated/graphql'
import { getDoppelLink } from '@/utils/reports/report_utils'

export function UploadedDarkwebReport({ id, brand_id, flagged_url, platform, status }) {
  const { data: brandsData, loading: brandsLoading } = useGetBrandNamesQuery({
    variables: {
      where: { id: { _eq: brand_id } },
    },
  })
  const brand_name = brandsData?.spoofing_entities?.[0]?.entity_name

  return (
    <VStack align="flex-start" marginBottom={2}>
      <Flex alignItems="center">
        <Link fontSize={15} href={getDoppelLink(id, 'darkweb')} marginRight={2}>
          {flagged_url}
        </Link>
      </Flex>

      <HStack>
        <Tag>Platform: {platform}</Tag>

        <Tag>Status: {unSnakeCase(status || '')}</Tag>

        <Tag>Brand: {brand_name}</Tag>
      </HStack>
    </VStack>
  )
}
