import React from 'react'
import { useDisclosure, Stack } from '@chakra-ui/react'
import DoppelDefaultButton from '../shared/doppel_default_button'
import { IoMdArrowUp } from 'react-icons/io'
import { ReportType } from '@/utils/constants'
import { useSelectedReportType } from '@/pages/reports'
import UploadBulkDarkWebCredentialLeakReportModal from './dark_web/upload_new_bulk_darkweb_credential_leak_report_modal'
import UploadBulkDarkWebCreditCardLeakReportModal from './dark_web/upload_new_bulk_darkweb_credit_card_report_modal'

export default function UploadNewBulkReportContainer({ refreshFunc }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedReportType] = useSelectedReportType()

  if (selectedReportType !== ReportType.DARK_WEB) {
    return <></>
  }
  return (
    <>
      <Stack direction="row" spacing={4}>
        <DoppelDefaultButton leftIcon={<IoMdArrowUp size="12px" />} onClick={onOpen}>
          Upload Bulk Alerts
        </DoppelDefaultButton>

        <UploadBulkDarkWebCredentialLeakReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />

        <UploadBulkDarkWebCreditCardLeakReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />
      </Stack>
    </>
  )
}
