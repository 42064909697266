import { IconButton, Tooltip } from '@chakra-ui/react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { DOPPEL_BLACK, DOPPEL_WHITE } from '../../utils/style'

// Toggle preview state via this button
export const ReportsPreviewToggle = ({ showPreviews, setShowPreviews }) => {
  const description = showPreviews ? 'Hide Previews' : 'Show Previews'

  return (
    <Tooltip fontSize="sm" label={description} placement="left">
      <IconButton
        aria-label={description}
        bgColor={DOPPEL_WHITE}
        icon={showPreviews ? <FaEye /> : <FaEyeSlash />}
        onClick={() => setShowPreviews(!showPreviews)}
        size="xs"
        textColor={DOPPEL_BLACK}
      />
    </Tooltip>
  )
}
