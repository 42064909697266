import { Flex, HStack, Tag, Text, VStack } from '@chakra-ui/react'
import React from 'react'

export function ErrorReportReason({ flagged_url, reason }) {
  return (
    <VStack align="flex-start" marginBottom={2}>
      <Flex alignItems="center">
        <Text fontSize={15} marginRight={2} wordBreak="break-all">
          {flagged_url}
        </Text>
      </Flex>

      <HStack>
        <Tag>{reason}</Tag>
      </HStack>
    </VStack>
  )
}
