import { NetworkStatus } from '@apollo/client/core/networkStatus'
import { Box, Flex, Spacer, Stack, TabList, Tabs } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import ReportsSearchBar from '@/components/collection_reports_table/reports_search_bar'
import RefreshButton from '@/components/tables/refresh_button'
import NavigableSpoofDetailModal from '@/components/web2/navigable_spoof_detail_modal'
import SpoofReportTab, { availableStatuses } from '@/components/web2/spoof_report_tab'
import { SpoofReportsTable } from '@/components/web2/spoof_reports_table'
import SpoofReportsTableFilterBar from '@/components/web2/spoof_reports_table_filter_bar'
import {
  useGetSpoofingReportsLazyQuery,
  useGetUserByIdQuery,
  useSearchSpoofingReportsCountQuery,
  useSearchSpoofingReportsQuery,
  Spoofing_Reports_Bool_Exp,
  Order_By,
} from '@/generated/graphql'
import { useIsEmployeeView, useOrgID, useUserID } from '@/hooks/id_token_claims'
import {
  spoofingReportsWhereClause,
  useSelectedPage,
  useSelectedPageSize,
  useSelectedSpoofReport,
} from '@/hooks/spoof_report_table_filters'
import {
  DarkWebPlatforms,
  ReportFilterType,
  ReportStatus,
  ReportType,
} from '@/utils/constants'
import { cleanUrl } from '@/utils/domain_utils'
import {
  defaultSortingMechanism,
  spoofingReportsOrderByClause,
} from '@/utils/reports/report_sorting_mechanism'
import {
  filterSpoofingReports,
  generateReportUrl,
  generateTableViewUrl,
} from '@/utils/reports/report_utils'
import { calculateTotalPageCount } from '@/utils/table_utils'
import {
  useReportStatuses,
  useSearchKey,
  useSelectedAutocompleteReportId,
  useSelectedReportFilters,
  useSelectedReportStatus,
  useSelectedReportType,
  useSelectedSortingMechanism,
} from '@/pages/reports'
import GenerateCSVButton from '@/components/csv/generate_csv_button'
import { ReportsPreviewToggle } from '@/components/web2/reports_preview_toggle'
import { processReportRowsForExport } from '@/utils/csv_generation/process_report_rows'
import CustomerRubricLink from './internal/customer_rubric_link'
import PlatformTabs from '@/components/web2/spoof_report_platform_tabs'
import { useAtom } from 'jotai'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import UnifiedReportsTableFilters from './admin/unified_reports_table_filters'
import UploadNewReportContainer from './web2/upload_new_report_container'
import UploadNewBulkReportContainer from './web2/upload_new_bulk_report_container'

type ReportsPageProps = {
  isOrgUnifiedView: boolean
}

const ReportsPage = ({ isOrgUnifiedView }: ReportsPageProps) => {
  const router = useRouter()
  const orgId = useOrgID()
  const [userId] = useUserID()
  const [toRefreshCache, setToRefreshCache] = useState(false)
  const [selectedPage, setSelectedPage] = useSelectedPage()
  const [selectedPageSize] = useSelectedPageSize()
  const [selectedSpoofReport, setSelectedSpoofReport] = useSelectedSpoofReport()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [reportStatuses, setReportStatuses] = useReportStatuses()
  const [selectedReportStatus, setSelectedReportStatus] = useSelectedReportStatus()
  const [selectedReportType] = useSelectedReportType()
  const [selectedSortingMechanism, setSelectedSortingMechanism] =
    useSelectedSortingMechanism()
  const [isEmployeeView] = useIsEmployeeView()
  const [searchKey] = useSearchKey()
  const [selectedAutocompleteReportId] = useSelectedAutocompleteReportId()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  // Should refactor to leverage selectedAutocompleteReportId and refactor other use cases of state
  // report ID to open on top of current logic
  const [overrideOpenReportId, setOverrideOpenReportId] = useState(null)

  const spoofReportsWhere = spoofingReportsWhereClause(
    selectedReportFilters,
    isOrgUnifiedView ? null : orgId,
    [selectedReportStatus],
    selectedReportType,
    selectedAutocompleteReportId,
    selectedReportExposureType,
  )

  const { data: userInfo } = useGetUserByIdQuery({
    variables: { id: userId },
  })

  const [showPreviews, setShowPreviews] = useState(
    userInfo?.users?.[0]?.app_settings?.default_report_preview_on ?? true,
  )

  const {
    data: searchSpoofingReportsData,
    loading: searchSpoofingReportsLoading,
    fetchMore: searchSpoofingReportsFetchMore,
    networkStatus: searchSpoofingReportsNetworkStatus,
    refetch: searchSpoofingReportsRefetch,
  } = useSearchSpoofingReportsQuery({
    variables: {
      offset: (selectedPage - 1) * selectedPageSize,
      limit: selectedPageSize,
      refresh: toRefreshCache,
      spoofingReportsWhere: spoofReportsWhere,
      searchKey: cleanUrl(searchKey),
      skipNormalSearch: !selectedAutocompleteReportId && Boolean(searchKey),
      skipFullTextSearch: Boolean(selectedAutocompleteReportId) || !searchKey,
      orderBy: spoofingReportsOrderByClause(searchKey, selectedSortingMechanism),
      includeCreditCardData:
        selectedReportExposureType == DarkWebPlatforms.CREDIT_CARD_LEAKS ? true : false,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: spoofingReportCount, refetch: spoofingReportCountRefetch } =
    useSearchSpoofingReportsCountQuery({
      variables: {
        spoofingReportsWhere: spoofReportsWhere,
        searchKey: cleanUrl(searchKey),
        skipNormalSearch: !selectedAutocompleteReportId && Boolean(searchKey),
        skipFullTextSearch: Boolean(selectedAutocompleteReportId) || !searchKey,
      },
      notifyOnNetworkStatusChange: true,
    })

  const spoofingReportWhere: Spoofing_Reports_Bool_Exp = {
    id: { _eq: overrideOpenReportId },
  }
  const [getReportById, { data: reportByIdData }] = useGetSpoofingReportsLazyQuery({
    variables: {
      spoofingReportsWhere: spoofingReportWhere,
      orderBy: [{ created_at: Order_By.Asc }],
    },
  })

  const spoofingReports =
    searchSpoofingReportsData?.search_spoofing_reports ||
    searchSpoofingReportsData?.spoofing_reports ||
    []

  const spoofingReportCounts =
    spoofingReportCount?.search_spoofing_reports_aggregate ||
    spoofingReportCount?.spoofing_reports_aggregate ||
    []

  const isDarkWeb = selectedReportType == ReportType.DARK_WEB

  useEffect(() => {
    if (orgId) {
      searchSpoofingReportsFetchMore({
        variables: { offset: (selectedPage - 1) * selectedPageSize },
      })
    }
  }, [orgId, selectedPage])

  useEffect(() => {
    setShowPreviews(
      userInfo?.users?.[0]?.app_settings?.default_report_preview_on ?? true,
    )
  }, [userInfo])

  useEffect(() => {
    setReportStatuses(availableStatuses(isEmployeeView, selectedReportType))
  }, [isEmployeeView, isDarkWeb]) // isDarkWeb instead of selectedReportType to reduce renders

  useEffect(() => {
    if (!selectedSpoofReport) setOverrideOpenReportId(null)
  }, [selectedSpoofReport])

  useEffect(() => {
    getReportById()
  }, [overrideOpenReportId])

  useEffect(() => {
    setSelectedSpoofReport(reportByIdData?.spoofing_reports[0] || null)
  }, [reportByIdData])

  const refreshTable = () => {
    setSelectedPage(1)
    setSelectedSpoofReport(null)
    setOverrideOpenReportId(null)
    searchSpoofingReportsRefetch()
    spoofingReportCountRefetch()
  }

  const createProcessReportRowsForExport = () => {
    return (rawRows, juicedMetrics, isEmployeeView) =>
      processReportRowsForExport(
        selectedReportType,
        rawRows,
        juicedMetrics,
        isEmployeeView,
      )
  }

  const createSpoofingReportsWhereClause = () => {
    return (selectedStatuses) =>
      spoofingReportsWhereClause(
        selectedReportFilters,
        orgId,
        selectedStatuses,
        selectedReportType,
        selectedAutocompleteReportId,
      )
  }

  // this won't be where this lives, but using for testing currently.
  // const alertDetailedViewFlagEnabled = useFeatureFlagEnabled('alert-detailed-view')

  useEffect(() => {
    if (!selectedReportType) return

    const reportUrl = generateReportUrl(
      selectedReportType,
      isOrgUnifiedView,
      selectedSpoofReport,
    )

    if (selectedSpoofReport) {
      window.history.replaceState(null, '', reportUrl)
    } else {
      const tableViewUrl = generateTableViewUrl(
        reportUrl,
        selectedReportFilters,
        selectedReportStatus,
        selectedSortingMechanism,
      )
      window.history.replaceState(null, '', tableViewUrl)
    }
  }, [
    selectedSpoofReport,
    selectedReportType,
    selectedReportFilters,
    selectedReportStatus,
    selectedSortingMechanism,
  ])

  useEffect(() => {
    if (toRefreshCache) {
      setToRefreshCache(false)
    }
  }, [toRefreshCache])

  // Not a huge fan of this, we should probably just use permalink to avoid caching issues and hacks like this
  // if selectedSearchBarReportId is set, user has clicked on autocomplete suggestion
  const freshSelectedReport = overrideOpenReportId
    ? selectedSpoofReport
    : selectedSpoofReport
    ? spoofingReports?.find((report) => report.id === selectedSpoofReport?.id)
    : null

  useEffect(() => {
    setSelectedSpoofReport(freshSelectedReport)
  }, [freshSelectedReport])

  useEffect(() => {
    setSelectedPage(1)
  }, [selectedReportFilters, selectedSortingMechanism])

  return (
    <>
      <Flex direction="column" width="100%">
        {selectedReportType === ReportType.DARK_WEB && (
          <Tabs
            colorScheme="doppel"
            index={Object.values(DarkWebPlatforms).indexOf(selectedReportExposureType)}
            marginBottom="40px"
            onChange={(index) => {
              if (index < Object.values(DarkWebPlatforms).length) {
                const newSelectedExposureType = Object.values(DarkWebPlatforms)[index]
                setSelectedReportExposureType(newSelectedExposureType)
                setSelectedPage(1)
              }
            }}
            size="sm"
          >
            <TabList>
              {Object.values(DarkWebPlatforms).map((exposureType) => (
                <div key={exposureType}>
                  <PlatformTabs
                    reportExposureType={exposureType}
                    spoofingReportsWhereClause={spoofReportsWhere}
                  />
                </div>
              ))}
            </TabList>
          </Tabs>
        )}

        <Stack marginBottom={6} spacing={3}>
          <Flex alignItems="center" direction="row">
            <ReportsSearchBar
              autocompleteClickCallback={setOverrideOpenReportId}
              isOrgUnifiedView={isOrgUnifiedView}
            />

            <RefreshButton refreshFunc={refreshTable} />

            <Spacer />

            {isOrgUnifiedView ? (
              <UnifiedReportsTableFilters />
            ) : (
              <>
                <CustomerRubricLink />

                <GenerateCSVButton
                  currentStatuses={reportStatuses.filter(
                    (status) => !status.toLowerCase().includes('internal'),
                  )}
                  getQuery={useGetSpoofingReportsLazyQuery}
                  orderBy={spoofingReportsOrderByClause(
                    searchKey,
                    selectedSortingMechanism,
                  )}
                  processData={createProcessReportRowsForExport()}
                  statuses={reportStatuses.filter(
                    (status) => !status.toLowerCase().includes('internal'),
                  )}
                  type={selectedReportType}
                  whereClause={createSpoofingReportsWhereClause()}
                />

                <UploadNewReportContainer refreshFunc={refreshTable} />

                <UploadNewBulkReportContainer refreshFunc={refreshTable} />
              </>
            )}
          </Flex>

          <SpoofReportsTableFilterBar
            isOrgUnifiedView={isOrgUnifiedView}
            selectedOrgIds={selectedReportFilters
              .filter((filter) => filter.filterType === ReportFilterType.Organization)
              .map((filter) => filter.value)}
          />
        </Stack>

        <Tabs
          colorScheme="doppel"
          index={reportStatuses.indexOf(
            selectedReportStatus || ReportStatus.ENUM_NEEDS_REVIEW,
          )}
          marginBottom="12px"
          onChange={(index) => {
            if (index < reportStatuses.length) {
              const newStatusType = reportStatuses[index]
              setSelectedReportStatus(newStatusType)
              setSelectedSortingMechanism(
                defaultSortingMechanism(
                  newStatusType,
                  selectedReportType,
                  isEmployeeView,
                ),
              )
              setSelectedPage(1)
            }
          }}
          size="sm"
        >
          <TabList>
            {reportStatuses.map((status) => (
              <div key={status}>
                <SpoofReportTab
                  reportStatus={status}
                  spoofingReportsWhereClause={spoofReportsWhere}
                />
              </div>
            ))}

            <Spacer />

            {!isEmployeeView ? (
              <ReportsPreviewToggle
                setShowPreviews={setShowPreviews}
                showPreviews={showPreviews}
              />
            ) : null}
          </TabList>
        </Tabs>

        <Box left="0px" overflowX="auto" overflowY="auto">
          <SpoofReportsTable
            data={filterSpoofingReports(
              searchSpoofingReportsData,
              selectedReportStatus,
            )}
            isOrgUnifiedView={isOrgUnifiedView}
            loading={searchSpoofingReportsLoading}
            setToRefreshCache={setToRefreshCache}
            showPreviews={showPreviews}
            totalPageCount={calculateTotalPageCount(
              spoofingReportCounts,
              selectedPageSize,
            )}
          />
        </Box>
      </Flex>

      {selectedSpoofReport && (
        <NavigableSpoofDetailModal
          loading={
            searchSpoofingReportsLoading ||
            searchSpoofingReportsNetworkStatus === NetworkStatus.fetchMore
          }
        />
      )}
    </>
  )
}

export default ReportsPage
