import { useSearchSpoofingReportsCountQuery } from '../../generated/graphql'
import { useSearchKey, useSelectedAutocompleteReportId } from '../../pages/reports'
import { cleanUrl } from '@/utils/domain_utils'
import PillTabs from '../shared/tabs/pill_tabs'

export default function PlatformTabs({
  reportExposureType,
  spoofingReportsWhereClause,
}: {
  reportExposureType?: any
  spoofingReportsWhereClause: any
}) {
  const [searchKey] = useSearchKey()
  const [selectedAutocompleteReportId] = useSelectedAutocompleteReportId()

  const updatedSpoofingReportsWhereClause =
    _updateSpoofingReportsWhereClauseRemoveStatus(spoofingReportsWhereClause)

  const { data: aggregateReportSearchCount, loading: loadingSearch } =
    useSearchSpoofingReportsCountQuery({
      variables: {
        spoofingReportsWhere: updatedSpoofingReportsWhereClause,
        searchKey: cleanUrl(searchKey),
        skipNormalSearch: !selectedAutocompleteReportId && Boolean(searchKey),
        skipFullTextSearch: Boolean(selectedAutocompleteReportId) || !searchKey,
      },
    })

  return (
    <PillTabs
      count={
        aggregateReportSearchCount?.spoofing_reports_aggregate?.aggregate?.count || 0
      }
      isLoading={loadingSearch}
      reportExposureType={reportExposureType}
    />
  )
}

function _updateSpoofingReportsWhereClauseRemoveStatus(spoofingReportsWhereClause) {
  const updatedSpoofingReportsWhereClause = structuredClone(spoofingReportsWhereClause)
  updatedSpoofingReportsWhereClause._and =
    updatedSpoofingReportsWhereClause._and.filter((clause) => !clause.report_status)
  return updatedSpoofingReportsWhereClause
}
